.review {
  margin-top: 16px;

  h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
  }
}

.policy {
  .modal-dialog {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .modal-content {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .modal {
    overflow: hidden !important;
  }
}

.fullscreen-modal {
  max-width: none !important;
  margin: 20px !important;
}

.preview-table-wrapper {
  overflow: scroll;
  height: calc(100vh - 335px);
  margin-top: 8px;
  @media screen and (max-width: 460px) {
    height: calc(100vh - 420px);
  }

  &::-webkit-scrollbar {
    height: 8px !important;
    width: 8px !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 5px;
  }
}

.comment {
  display: flex;
  margin-top: 16px;
}

.links {
  background-color: #f3f3f3;
  margin-top: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (max-width: 768px) {
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
}

.button {
  border: 1px solid #ced4da;
  border-radius: 6px;
  background-color: #fff;
  max-width: 380px;
  width: 100%;
  margin-inline: auto;

  &_link {
    display: inline-block;
    padding: 16px;
  }

  &_heading {
    font-size: 16px;
    font-weight: 600;
    color: #363636;
    margin-left: 8px;
  }
}

#consumer-root {
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &::-webkit-scrollbar {
    width: 8px !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 5px;
  }

  .consumer-header {
    // position: absolute;
    margin: 0 auto;
    background: #f3f3f3;
    padding: 5px 0;
    // height: 50px;
    width: 100%;
  }
  .consumer-container {
    .adjust-width,
    .adjust-width-expand,
    .hero {
      max-width: 800px;
      margin: 0 auto;
      padding: 0;
    }
    .adjust-width-expand {
      padding: 0;
    }
    @media screen and (max-width: 800px) {
      .adjust-width,
      .hero {
        padding: 0 1rem;
      }
    }
    .preview & {
      .adjust-width,
      .hero {
        padding: 0 1rem;
      }
    }
    .card .card-content {
      padding: 0.5rem 1rem;
    }
  }
}
